import React from 'react'

const About = () => {
    return (
        <div className="h-screen flex justify-center items-center bg-yellow-300">
            <h1>About ...</h1>
            <p>Some text</p>
        </div>
    )
}

export default About
