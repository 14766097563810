import React from 'react'
import ImageOne from '../images/64331231_10214447965848610_908427824107356160_n.jpg'
import ImageTwo from '../images/137614820_1144014189390240_8781416517476652668_n.jpg'

const Content = () => {
    return (
        <>
        <div className="menu-card">
            <img src={ImageOne} alt="img1" className="h-48 rounded mb-20 shadow"></img>
            <div className="center-content">
                <h2 className="text-2xl mb-2">Egg-muffins</h2>
                <p className="mb-2">Dit is de tekst er onder</p>
                <span>tesje</span>
            </div>
        </div>
        <div className="menu-card">
            <img src={ImageTwo} alt="img1" className="h-48 rounded mb-20 shadow"></img>
            <div className="center-content">
                <h2 className="text-2xl mb-2">Egg-salad</h2>
                <p className="mb-2">Dit is de tekst er onder</p>
                <span>tesje</span>
            </div>
        </div>
        </>
    )
}

export default Content
