import React from 'react'

const Contact = () => {
    return (
        <div className="h-screen flex justify-center items-center bg-red-300">
            <h1>Contact ...</h1>
            <p>Some text</p>
        </div>
    )
}

export default Contact
