import React from 'react'

const Footer = () => {
    return (
        <div className="flex justify-center items-center h-16 bg-black text-white">
            <p>Dit de footertest</p> 
        </div>
    )
}

export default Footer
